import { OptimizerResourcesEnum } from "shared/enums/resourceMappings";
import { AutocompleteInput, DateInput, Edit, ReferenceInput, required, SimpleForm } from "react-admin";

export default function GroupsCertificationsEdit() {
  return (
    <Edit>
      <SimpleForm>
        <DateInput source="name" fullWidth />
        <ReferenceInput source="certification_id" reference={OptimizerResourcesEnum.OptiCertifications}>
          <AutocompleteInput
            optionText={({ id, certif_name }: any) => {
              return `${id} - ${certif_name}`;
            }}
            validate={[required()]}
            fullWidth
          />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
}
