import { AfrrEngagementResourcesEnum, AfrrEngagementResourceMapping } from "shared/enums/resourceMappings";
import { Alert, Box, useMediaQuery, useTheme } from "@mui/material";
import axios, { AxiosError } from "axios";
import { addDays, format, formatISO, isBefore, parseISO, setHours, setMinutes, setSeconds } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { generateRandomString } from "shared/utils/helpers";
import useStatusAware from "shared/hooks/useStatusAware";
import * as React from "react";
import { Dispatch, useCallback, useEffect } from "react";
import {
  ArrayInput,
  Button,
  Datagrid,
  DateField,
  Form,
  ListContextProvider,
  Loading,
  NumberField,
  NumberInput,
  RaRecord,
  SelectField,
  SelectInput,
  SimpleFormIterator,
  required,
  useList,
  useNotify,
} from "react-admin";
import { FieldValues, SubmitHandler } from "react-hook-form";
import { AfrrBidDirectionEnum, AfrrGateType, BidTimeseriesType } from "./types";
import { validateInteger } from "shared/utils/validations";

type EditCurrentBidTimeseriesProps = {
  date: Date;
  handleCloseDialog: () => void;
  setTriggerRefresh: Dispatch<React.SetStateAction<number>>;
};

const EditCurrentBidTimeseries = ({ date, handleCloseDialog, setTriggerRefresh }: EditCurrentBidTimeseriesProps) => {
  const { error, loading, data: gate, setError, setLoading, setData: setGate } = useStatusAware();

  function isOneDayBeforeBefore9amParis(date: Date): boolean {
    const parisTimeZone = "Europe/Paris";

    // Get current date and time in Paris time zone
    const now = new Date();

    // Set the target date to one day before the given date
    const oneDayBefore = addDays(date, -1);

    // Set the target time to 9 AM Paris time on the target date
    const nineAmParisTime = setSeconds(setMinutes(setHours(oneDayBefore, 9), 0), 0);

    // Convert the target 9 AM time to UTC
    const nineAmParisTimeUtc = zonedTimeToUtc(nineAmParisTime, parisTimeZone);

    // Check if now is before 9 AM Paris time on the target date
    return isBefore(now, nineAmParisTimeUtc);
  }

  const fetchGateDocument = useCallback(async () => {
    const baseUrl = process.env.REACT_APP_AFRR_ENGAGEMENT_API_URL;
    // ----------------------------------------------------------------------------
    // TODO: uncomment this line when the backend is fixed and remove the next line
    // const urlRelativePath = `${AfrrEngagementResourcesEnum.GateDocument}/accepted_gate_with_bts_and_points/`;
    const urlRelativePath = `${AfrrEngagementResourcesEnum.GateDocument}/accepted_gate_with_bts_and_points/{delvery_date}`;
    // ----------------------------------------------------------------------------
    const deliveryDateParams = `delivery_date=${format(date, "yyyy-MM-dd")}`;
    const url = `${baseUrl}/${urlRelativePath}?${deliveryDateParams}`;

    setLoading(true);
    try {
      const { data } = await axios.get(url);
      if (typeof data === "string") {
        throw new AxiosError(data);
      }
      setGate(data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [date, setGate, setError, setLoading]);

  useEffect(() => {
    fetchGateDocument();
  }, [fetchGateDocument]);

  if (loading) return <Loading />;

  return (
    <Box>
      {error && (
        <Alert variant="filled" severity={"error"}>
          {error?.response?.data?.message || error?.message || "An error occurred while fetching bid timeseries"}
        </Alert>
      )}
      {isOneDayBeforeBefore9amParis(date) ? (
        <EditBidTimeseries
          data={gate}
          date={date}
          handleCloseDialog={handleCloseDialog}
          setTriggerRefresh={setTriggerRefresh}
        />
      ) : (
        <DisplayBidTimeseries data={gate?.bid_time_series || []} />
      )}
    </Box>
  );
};
export default EditCurrentBidTimeseries;

type EditBidTimeseriesProps = {
  data: AfrrGateType;
  date: Date;
  handleCloseDialog: () => void;
  setTriggerRefresh: Dispatch<React.SetStateAction<number>>;
};

function EditBidTimeseries({ data, date, handleCloseDialog, setTriggerRefresh }: EditBidTimeseriesProps) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const { error, setError } = useStatusAware();
  const notify = useNotify();

  const formatDate = (hours: number, currentDate: Date) => {
    const parisTimeZone = "Europe/Paris";
    // Get the current date in Paris timezone
    const nowInParis = utcToZonedTime(currentDate, parisTimeZone);
    // Set the hours and minutes in Paris timezone
    nowInParis.setHours(+hours);
    nowInParis.setMinutes(0);
    nowInParis.setSeconds(0);
    nowInParis.setMilliseconds(0);
    // Convert the Paris timezone date to UTC
    const utcDate = zonedTimeToUtc(nowInParis, parisTimeZone);
    return formatISO(utcDate);
  };

  const handleSubmit: SubmitHandler<FieldValues> = async (values) => {
    const bids = values.bidTimeseries.reduce((acc: BidTimeseriesType[], bid: BidTimeseriesType) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { created_at, updated_at, deleted_at, id, mrid, gate_document_id, symetrical_feature, ...rawBid } = bid;
      const preparedBid: BidTimeseriesType = {
        ...rawBid,
        start_date: formatDate(+bid.start_date, date),
        end_date: formatDate(+bid.end_date, date),
        divisible: rawBid.divisible || "A01",
      };

      if (preparedBid.direction === AfrrBidDirectionEnum.SYMETRICAL) {
        preparedBid.symetrical_feature = generateRandomString(3) as string;
        return [
          ...acc,
          { ...preparedBid, direction: AfrrBidDirectionEnum.UPWARD },
          { ...preparedBid, direction: AfrrBidDirectionEnum.DOWNWARD },
        ];
      } else {
        return [...acc, preparedBid];
      }
    }, []);

    const preparedData = {
      delivery_date: format(date, "yyyy-MM-dd"),
      bid_time_series: bids,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_AFRR_ENGAGEMENT_API_URL}/${
          AfrrEngagementResourceMapping[AfrrEngagementResourcesEnum.BidTimeSeries]
        }/bid_document`,
        preparedData,
      );
      notify("Bid timeseries posted successfully", { type: "success" });
      setTriggerRefresh((prev) => prev + 1);
      handleCloseDialog();
    } catch (error) {
      setError(error);
      console.error("An error occurred while posting bid timeseries:", error);
    }
  };

  const defaultValues = {
    bidTimeseries: data?.bid_time_series
      ? data?.bid_time_series.map((bid: BidTimeseriesType) => ({
          ...bid,
          start_date: parseISO(bid.start_date as string).getHours(),
          end_date: parseISO(bid.end_date as string).getHours(),
        }))
      : [
          {
            start_date: 0,
            end_date: 1,
            direction: AfrrBidDirectionEnum.UPWARD,
            offered_quantity: "",
            offered_price: "",
          },
        ],
  };

  const getErrorMessage = (error: any) => {
    if (error.response?.data?.detail && typeof error.response.data.detail === "string") {
      return error.response.data.detail;
    }
    if (error.response?.data?.detail && error.response.data.detail instanceof Array) {
      return error.response.data.detail[0]?.msg;
    }
    if (error?.message) {
      return error.message;
    }
    return "An error occurred while posting bid timeseries";
  };

  const hoursChoices = [
    { id: 0, name: "00h" },
    { id: 1, name: "01h" },
    { id: 2, name: "02h" },
    { id: 3, name: "03h" },
    { id: 4, name: "04h" },
    { id: 5, name: "05h" },
    { id: 6, name: "06h" },
    { id: 7, name: "07h" },
    { id: 8, name: "08h" },
    { id: 9, name: "09h" },
    { id: 10, name: "10h" },
    { id: 11, name: "11h" },
    { id: 12, name: "12h" },
    { id: 13, name: "13h" },
    { id: 14, name: "14h" },
    { id: 15, name: "15h" },
    { id: 16, name: "16h" },
    { id: 17, name: "17h" },
    { id: 18, name: "18h" },
    { id: 19, name: "19h" },
    { id: 20, name: "20h" },
    { id: 21, name: "21h" },
    { id: 22, name: "22h" },
    { id: 23, name: "23h" },
    { id: 24, name: "00h (J+1)" },
  ];

  return (
    <Form defaultValues={defaultValues} onSubmit={handleSubmit} mode="onBlur" reValidateMode="onBlur">
      {error && (
        <Alert variant="filled" severity={"error"}>
          {getErrorMessage(error)}
        </Alert>
      )}
      <ArrayInput source="bidTimeseries">
        <SimpleFormIterator
          inline={!isMd}
          sx={
            isMd
              ? {
                  "& .RaSimpleFormIterator-form": {
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                  },
                }
              : {}
          }
        >
          <SelectInput source="start_date" validate={required()} choices={hoursChoices} />
          <SelectInput source="end_date" validate={required()} choices={hoursChoices} />
          <SelectInput
            source="direction"
            validate={[required()]}
            choices={[
              { id: AfrrBidDirectionEnum.UPWARD, name: AfrrBidDirectionEnum.UPWARD },
              { id: AfrrBidDirectionEnum.DOWNWARD, name: AfrrBidDirectionEnum.DOWNWARD },
              { id: AfrrBidDirectionEnum.SYMETRICAL, name: AfrrBidDirectionEnum.SYMETRICAL },
            ]}
          />
          <NumberInput source="offered_quantity" validate={[required(), validateInteger]} />
          <NumberInput source="offered_price" validate={[required()]} />
        </SimpleFormIterator>
      </ArrayInput>
      <Button type="submit" label="POST Bid timeseries" variant="contained" />
    </Form>
  );
}

type DisplayBidTimeseriesProps = {
  data: RaRecord[];
};

function DisplayBidTimeseries({ data }: DisplayBidTimeseriesProps) {
  const listContext = useList({ data });
  return (
    <ListContextProvider value={listContext}>
      <Datagrid bulkActionButtons={false}>
        <NumberField source="id" />
        <DateField source="start_date" />
        <DateField source="end_date" />
        <SelectField
          source="direction"
          choices={[
            { id: AfrrBidDirectionEnum.UPWARD, name: AfrrBidDirectionEnum.UPWARD },
            { id: AfrrBidDirectionEnum.DOWNWARD, name: AfrrBidDirectionEnum.DOWNWARD },
          ]}
        />
        <NumberField source="offered_quantity" />
        <NumberField source="offered_price" />
      </Datagrid>
    </ListContextProvider>
  );
}
