import { SiteResourceMapping, SiteResourcesEnum } from "shared/enums/resourceMappings";
import axios from "axios";

interface PlanningValues {
  sites?: SiteValues[];
}

interface SiteValues {
  site_id?: number;
  plannings?: PlanningItemValues[];
}

interface PlanningItemValues {
  start_date?: string;
  end_date?: string;
  overidable?: boolean;
  mode?: string;
  fcr_engagement?: number | null;
  fcrn_engagement?: number | null;
  afrr_engagement?: number | null;
  target_power_mw?: number | null;
  soc_restoration_power?: number | null;
}

interface PlanningErrors {
  sites?: SiteErrors[];
}

interface SiteErrors {
  plannings?: PlanningItemErrors[];
}

interface PlanningItemErrors {
  start_date?: string;
  end_date?: string;
  mode?: string;
  fcr_engagement?: string;
  fcrn_engagement?: string;
  afrr_engagement?: string;
  target_power_mw?: string;
}

const getSitesInfos = async (siteIds: number[]) => {
  try {
    const url = `${process.env.REACT_APP_SITE_API_URL}/${SiteResourceMapping[SiteResourcesEnum.Sites]}`;
    const { data } = await axios({
      method: "get",
      url,
      params: {
        id__in: siteIds.join(","),
        page: 1,
        size: 100,
      },
    });
    return data.items;
  } catch (error) {
    console.error("Error fetching site infos:", error);
    return [];
  }
};

const getSitesIds = (sites: SiteValues[]): number[] => {
  return sites.map((site) => site.site_id).filter((siteId): siteId is number => !!siteId);
};

export const validatePlanning = async (values: PlanningValues): Promise<PlanningErrors> => {
  const errors: PlanningErrors = {};

  if (values.sites) {
    // const allSiteIds = values.sites.map((site) => site.site_id);
    const siteIds = getSitesIds(values.sites);
    const sitesInfos = siteIds.length ? await getSitesInfos(siteIds) : [];
    const siteErrorsArray = values.sites
      .map((site) => {
        const siteErrors: SiteErrors = {};
        if (site.plannings) {
          const planningErrorsArray = site.plannings
            .map((planning) => {
              const planningErrors: PlanningItemErrors = {};

              // Validate dynamic fields if they are present
              const dynamicFields: (keyof PlanningItemErrors)[] = [
                "fcr_engagement",
                "fcrn_engagement",
                "afrr_engagement",
                "target_power_mw",
              ];

              const targetedSite = sitesInfos.find((siteInfo: any) => siteInfo.id === site.site_id);

              dynamicFields.forEach((field) => {
                const fieldValue = planning[field as keyof PlanningItemValues];
                if (fieldValue !== undefined) {
                  if (fieldValue === null || fieldValue === "") {
                    planningErrors[field] = "Required";
                  } else if (typeof fieldValue === "number" && fieldValue < 0) {
                    planningErrors[field] = "Must be at least 0";
                  } else if (
                    typeof fieldValue === "number" &&
                    targetedSite.max_power_mw &&
                    targetedSite.max_power_mw > 0 &&
                    fieldValue > targetedSite.max_power_mw
                  ) {
                    planningErrors[field] = `Must be at most ${targetedSite.max_power_mw}`;
                  }
                }
              });

              // Validate mode
              if (!planning.mode) {
                planningErrors.mode = "Mode is required";
              }

              // Validate start_date and end_date
              const now = new Date();

              if (!planning.start_date) {
                planningErrors.start_date = "Start date is required";
              } else {
                const startDate = new Date(planning.start_date);
                if (startDate < now) {
                  planningErrors.start_date = "Start date cannot be in the past";
                } else if (startDate.getMinutes() % 5 !== 0) {
                  planningErrors.start_date = "Start date must be a multiple of 5 minutes";
                }
              }

              if (!planning.end_date) {
                planningErrors.end_date = "End date is required";
              } else {
                const endDate = new Date(planning.end_date);
                if (endDate < now) {
                  planningErrors.end_date = "End date cannot be in the past";
                } else if (endDate.getMinutes() % 5 !== 0) {
                  planningErrors.end_date = "End date must be a multiple of 5 minutes";
                }
              }

              if (planning.start_date && planning.end_date) {
                const startDate = new Date(planning.start_date);
                const endDate = new Date(planning.end_date);
                if (startDate >= endDate) {
                  planningErrors.start_date = "Start date must be before end date";
                  planningErrors.end_date = "End date must be after start date";
                }
              }

              return Object.keys(planningErrors).length > 0 ? planningErrors : null;
            })
            .filter((planningError): planningError is PlanningItemErrors => planningError !== null);

          if (planningErrorsArray.length > 0) {
            siteErrors.plannings = planningErrorsArray;
          }
        }

        return Object.keys(siteErrors).length > 0 ? siteErrors : null;
      })
      .filter((siteError): siteError is SiteErrors => siteError !== null);

    if (siteErrorsArray.length > 0) {
      errors.sites = siteErrorsArray;
    }
  }

  return errors;
};
