import { SiteResourcesEnum } from "shared/enums/resourceMappings";
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  DeleteButton,
  FunctionField,
  RaRecord,
} from "react-admin";

export const NLevelLossList = () => (
  <List>
    <Datagrid>
      <TextField source="id" />
      <DateField source="no_n_level_start" locales={"fr-FR"} showTime />
      <DateField source="no_n_level_end" locales={"fr-FR"} showTime />
      <ReferenceField source="group_id" reference={SiteResourcesEnum.Groups} link={false}>
        <FunctionField
          render={(record: RaRecord) => `${record.id} ${record.name} ${record.code} | mode: ${record.mode}`}
        />
      </ReferenceField>
      <DeleteButton />
    </Datagrid>
  </List>
);
