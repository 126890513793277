import { PlanningResourcesEnum, PlanningResourceMapping, SiteResourcesEnum } from "shared/enums/resourceMappings";
import axios from "axios";
import { FetchInfosType, PaginationType } from "shared/types";
import { formatISO } from "date-fns";
import useFetchPaginatedResource from "shared/hooks/useFetchPaginatedResource";
import { useCallback } from "react";
import { SortPayload, useDataProvider } from "react-admin";

export default function useFetchMasksChartData({ dateRange, groupIds }: FetchInfosType) {
  const dataprovider = useDataProvider();

  const fetchMasks = useCallback(
    async ({ page, size }: PaginationType) => {
      const {
        data: { items, total },
      } = await axios({
        method: "get",
        url: `${process.env.REACT_APP_PLANNINGS_API_URL}/${
          PlanningResourceMapping[PlanningResourcesEnum.PlanningMasks]
        }trimmed/`,
        params: {
          start_date: formatISO(dateRange.startDate),
          end_date: formatISO(dateRange.endDate),
          page,
          size,
        },
      });
      return { items, total };
    },
    [dateRange.startDate, dateRange.endDate],
  );

  const fetchEdrs = useCallback(
    async ({ page, size }: PaginationType) => {
      const config = {
        sort: { field: "id", order: "ASC" } as SortPayload,
        pagination: { page, perPage: size },
        filter: {
          type: "rte_edp",
          ...(groupIds.length > 0 && { id__in: groupIds }),
        },
      };
      const { data: items, total } = await dataprovider.getList(SiteResourcesEnum.Groups, config);
      return { items, total: total as number };
    },
    [dataprovider, groupIds],
  );

  const {
    error: maskError,
    loading: maskLoading,
    items: rawMasks,
  } = useFetchPaginatedResource(fetchMasks, 100, [dateRange]);
  const { error: edrError, loading: edrLoading, items: rawEdrs } = useFetchPaginatedResource(fetchEdrs, 100);

  const error = maskError || edrError;
  const loading = maskLoading || edrLoading;
  const masks = rawMasks && rawMasks.length > 0 ? rawMasks : [];
  const edrs = rawEdrs && rawEdrs.length > 0 ? rawEdrs : [];

  return { error, loading, masks, edrs };
}
