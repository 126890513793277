import { SiteResourcesEnum } from "shared/enums/resourceMappings";
import { AutocompleteInput, Create, DateTimeInput, ReferenceInput, SimpleForm } from "react-admin";

export default function NlevelLossCreate() {
  return (
    <Create>
      <SimpleForm>
        <DateTimeInput source="no_n_level_start" label="Start of N level Loss" sx={{ minWidth: 300 }} />
        <DateTimeInput source="no_n_level_end" label="End of N level Loss" sx={{ minWidth: 300 }} />
        <ReferenceInput
          source="group_id"
          reference={SiteResourcesEnum.Groups}
          filter={{
            type: "rte_edr",
          }}
        >
          <AutocompleteInput
            sx={{ minWidth: 300 }}
            optionText={({ id, name, code }: any) => {
              return `${id} - ${name} - ${code}`;
            }}
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}
