import { SiteResourcesEnum } from "shared/enums/resourceMappings";
import { Alert, Box } from "@mui/material";
import { format, subHours } from "date-fns";
import useFetchPaginatedResource from "shared/hooks/useFetchPaginatedResource";
import { useCallback, useMemo } from "react";
import { Loading, SortPayload, useDataProvider, useRecordContext } from "react-admin";
import ReactApexChart from "react-apexcharts";
import { PaginationType } from "shared/types";

export default function NLevelGraphVisualization() {
  const CHART_HEIGHT = 400;

  const record = useRecordContext();
  const site_ids = useMemo(() => (record ? record.site_ids : []), [record]);
  const dataprovider = useDataProvider();

  const fetchNLevels = useCallback(
    async ({ page, size }: PaginationType) => {
      const now = new Date();
      const threeHoursAgo = subHours(now, 3);
      const config = {
        sort: { field: "id", order: "ASC" } as SortPayload,
        pagination: { page: page, perPage: size },
        filter: {
          site_id: site_ids[0],
          timestamp__gte: threeHoursAgo.toISOString(),
        },
      };
      const { data: items, total } = await dataprovider.getList(SiteResourcesEnum.NLevels, config);
      return { items, total: total as number };
    },
    [dataprovider, site_ids],
  );

  const { error, loading, items } = useFetchPaginatedResource(fetchNLevels, 1000);

  const formattedData = useMemo(() => {
    return items.map((item: any) => ({
      x: new Date(item.timestamp).getTime(),
      y: item.value,
    }));
  }, [items]);

  if (loading)
    return (
      <Loading
        sx={{
          maxHeight: CHART_HEIGHT,
        }}
      />
    );

  if (error)
    return (
      <Alert
        severity="error"
        sx={{
          maxHeight: CHART_HEIGHT,
        }}
      >
        {error.message}
      </Alert>
    );

  const options: ApexCharts.ApexOptions = {
    chart: {
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text: "N levels",
      align: "center",
    },
    tooltip: {
      x: {
        formatter: function (siteName: any) {
          if (!Number.isNaN(parseFloat(siteName))) {
            return format(new Date(siteName), "dd/MM/yyyy HH:mm:ss"); // Formats tooltip x-axis value as a date if it's a number.
          }
          return `${siteName}`; // Otherwise, returns the string value (site name).
        },
      },
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
      padding: {
        bottom: 50, // Adds padding at the bottom of the chart to prevent labels from being clipped.
      },
    },
    markers: {
      size: 0,
      //   colors: ["red"],
    },
    xaxis: {
      type: "datetime",
      labels: {
        offsetY: 5,
        formatter: function (value) {
          // Custom formatting for x-axis datetime labels
          return format(new Date(value), "dd/MM/yyyy HH:mm"); // Customizes the label format on the x-axis to display dates in "dd/MM/yyyy HH:mm" format.
        },
        rotate: -25, // Rotates the x-axis labels by -45 degrees for better readability.
        rotateAlways: true, // Ensures that labels are always rotated, even if there's enough space for them without rotation.
      },
    },
    yaxis: {
      title: {
        text: "N level values",
      },
      min: -1,
      max: 1,
      tickAmount: 10, // Ensures that the axis is divided into 10 ticks (1/0.2)
      labels: {
        formatter: function (value) {
          return value.toFixed(1); // Show only one decimal point to align with multiples of 0.2
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          y2: 1,
          fillColor: "rgba(69, 245, 66, 0.7)",
        },
        {
          y: 0,
          y2: -1,
          fillColor: "pink",
        },
      ],
    },
    colors: ["#0000FF"],
  };

  const series = [
    {
      name: "N Level Values",
      data: formattedData, // Pass the transformed data
    },
  ];

  return (
    <Box>
      <ReactApexChart options={options} series={series} type="line" height={CHART_HEIGHT} />
    </Box>
  );
}
