import { SiteResourcesEnum } from "shared/enums/resourceMappings";
import { Box, Divider, Stack, Step, StepLabel, Stepper } from "@mui/material";
import ExplicitValuesBoolenInput from "shared/components/inputs/ExplicitValuesBooleanInput";
import CustomDeletedDialogToolbar from "shared/components/toolbars/CustomDeletedDialogToolbar";
import { RteComputationVersionEnum, SiteContryEnum, SiteOperatorEnum } from "shared/enums/globalEnums";
import {
  rteComputationVersionChoices,
  siteCountryChoices,
  siteFinlandDsoChoices,
  siteFinlandSupplierChoices,
  siteFranceSupplierChoices,
  siteFrenchDsoChoices,
  siteModeChoices,
  siteOperatorChoices,
} from "shared/enums/reactAdminChoices";
import { getDataproviderCustomErrorMessage } from "shared/utils/errorHandlers";
import useStepper from "shared/hooks/useStepper";
import { ChangeEvent, FC, useCallback, useMemo } from "react";
import {
  AutocompleteArrayInput,
  Button,
  FormDataConsumer,
  NumberInput,
  RaRecord,
  ReferenceArrayInput,
  SaveButton,
  SelectInput,
  SelectInputProps,
  SimpleForm,
  TextInput,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRedirect,
} from "react-admin";
import { FieldValues, SubmitHandler, useFormContext } from "react-hook-form";
import { siteValidations } from "./validations";

type SitesBaseFormProps = {
  isCreateForm?: boolean;
};

export default function SitesBaseForm({ isCreateForm = true }: SitesBaseFormProps) {
  const notify = useNotify();

  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const record = useRecordContext();
  const { activeStep, handleNext, handleBack } = useStepper();

  const steps = [
    "Basic Information",
    "Aggregation Details",
    "Location Information",
    "Network Information",
    "Capacity and Efficiency",
    "Advanced Settings",
    ...(isCreateForm ? ["Site Configuration"] : []),
  ];

  const handleSubmit: SubmitHandler<FieldValues> = useCallback(
    async (data) => {
      try {
        if (isCreateForm) {
          await dataProvider.create(SiteResourcesEnum.Sites, { data });
          notify("Site and configuration created successfully", { type: "success" });
        } else {
          await dataProvider.update(SiteResourcesEnum.Sites, { id: data.id, data, previousData: record });
          notify("Site updated successfully", { type: "success" });
        }
        redirect("list", SiteResourcesEnum.Sites);
      } catch (error: any) {
        notify(getDataproviderCustomErrorMessage(error), { type: "error" });
      }
    },
    [isCreateForm, dataProvider, notify, record, redirect],
  );

  const isLastStep = activeStep === steps.length - 1;
  const isClickable = !isCreateForm || (isCreateForm && isLastStep);

  return (
    <SimpleForm
      mode="onBlur"
      validate={siteValidations}
      onSubmit={handleSubmit}
      toolbar={
        <CustomDeletedDialogToolbar isCreateMode={isCreateForm} resource={SiteResourcesEnum.Sites} resourceKey={"Site"}>
          <StepNavigationButtons
            activeStep={activeStep}
            steps={steps}
            handleNext={handleNext}
            handleBack={handleBack}
          />

          <SaveButton label="Submit" size="large" disabled={!isClickable} />
        </CustomDeletedDialogToolbar>
      }
    >
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Divider sx={{ mt: 4, mb: 4 }} />
        <Stack
          direction="column"
          spacing={2}
          sx={{
            justifyContent: "space-between",
            height: "100%",
            mt: 2,
            minHeight: "500px",
          }}
        >
          <Box>
            <FormStepContainer hidden={activeStep !== 0}>
              {isCreateForm && <NumberInput fullWidth source="id" />}
              <TextInput fullWidth source="name" />
              <CustomSelectCountryInput fullWidth source="country" choices={siteCountryChoices} />
              <CountryDependentInputs />
              <ExplicitValuesBoolenInput label="IECharge" falseLabel="No" trueLabel="Yes" source="iecharge" />
            </FormStepContainer>
            <FormStepContainer hidden={activeStep !== 1}>
              <FormDataConsumer>
                {({ formData }) => {
                  if (formData.country !== SiteContryEnum.France) return <></>;
                  return <TextInput fullWidth source="rte_code" label="Tranche TM site" />;
                }}
              </FormDataConsumer>
              <SelectInput fullWidth source="mode" choices={siteModeChoices} />
              <ReferenceArrayInput fullWidth source="group_ids" reference={SiteResourcesEnum.Groups}>
                <AutocompleteArrayInput optionText="name" />
              </ReferenceArrayInput>
            </FormStepContainer>
            <FormStepContainer hidden={activeStep !== 2}>
              <TextInput fullWidth source="city" />
              <NumberInput fullWidth source="zipcode" />
              <NumberInput fullWidth source="latitude" />
              <NumberInput fullWidth source="longitude" />
            </FormStepContainer>
            <FormStepContainer hidden={activeStep !== 3}>
              <TextInput fullWidth source="network_ip" />
              <TextInput fullWidth source="aggregator_module_healthcheck_url" />
            </FormStepContainer>
            <FormStepContainer hidden={activeStep !== 4}>
              <NumberInput fullWidth source="max_cycles" />
              <NumberInput fullWidth source="rack_count" />
              <NumberInput fullWidth source="min_power_mw" />
              <NumberInput fullWidth source="max_power_mw" />
              <NumberInput fullWidth source="efficiency" />
              <NumberInput fullWidth source="max_soc_mwh" />
              <NumberInput fullWidth source="soh" />
            </FormStepContainer>
            <FormStepContainer hidden={activeStep !== 5}>
              <NumberInput fullWidth source="useful_energy_at_1mw" />
            </FormStepContainer>
            <FormStepContainer hidden={!isCreateForm || activeStep !== 6}>
              <NumberInput fullWidth source="desired_configuration.power_mw" defaultValue={1} />
              <NumberInput fullWidth source="desired_configuration.efficiency_roundtrip" defaultValue={0.85} />
              <SelectInput
                fullWidth
                source="desired_configuration.rte_fcr_computation_version"
                choices={rteComputationVersionChoices}
                defaultValue={RteComputationVersionEnum.t_tenue}
              />
            </FormStepContainer>
          </Box>
        </Stack>
      </Box>
    </SimpleForm>
  );
}

interface CustomSelectInputProps extends SelectInputProps {
  onChange?: (event: ChangeEvent<HTMLInputElement> | RaRecord) => void;
}

const CustomSelectCountryInput: React.FC<CustomSelectInputProps> = (props: CustomSelectInputProps) => {
  const { onChange, ...rest } = props;
  const { setValue } = useFormContext();

  const handleChange = (event: ChangeEvent<HTMLInputElement> | RaRecord) => {
    // Keep the default React Admin behavior
    onChange && onChange(event);

    // Reset the dependent fields
    setValue("dso", "");
    setValue("site_operator", "");
    setValue("supplier", "");
  };

  return <SelectInput {...rest} onChange={handleChange} />;
};

const CountryDependentInputs = () => {
  const { getValues } = useFormContext();
  const country = getValues("country");

  const isFinland = country === SiteContryEnum.Finland;
  const disabled = !country;
  const helperText = disabled ? "You must choose a country first" : undefined;

  const dsoChoices = isFinland ? siteFinlandDsoChoices : siteFrenchDsoChoices;
  const supplierChoices = isFinland ? siteFinlandSupplierChoices : siteFranceSupplierChoices;

  const operatorChoices = useMemo(() => {
    return siteOperatorChoices.filter(({ id }) =>
      isFinland ? id === SiteOperatorEnum.NWJ_FIN_Oy : id !== SiteOperatorEnum.NWJ_FIN_Oy,
    );
  }, [isFinland]);

  const defaultInputProps = {
    disabled,
    helperText,
  };

  return (
    <>
      <SelectInput fullWidth source="dso" choices={dsoChoices} {...defaultInputProps} />
      <SelectInput fullWidth source="site_operator" choices={operatorChoices} label="SPV" {...defaultInputProps} />
      <SelectInput fullWidth source="supplier" choices={supplierChoices} {...defaultInputProps} />
      <TextInput
        fullWidth
        source="prm"
        label={isFinland ? "Consumption point number (GSRN)" : "PRM"}
        {...defaultInputProps}
      />
    </>
  );
};

const FormStepContainer = ({ children, hidden }: { children: React.ReactNode; hidden?: boolean }) => (
  <Box display={hidden ? "none" : "grid"} gridTemplateColumns="repeat(3, 1fr)" gap={2}>
    {children}
  </Box>
);

interface StepNavigationButtonsProps {
  activeStep: number;
  steps: string[];
  handleNext: () => void;
  handleBack: () => void;
}

const StepNavigationButtons: FC<StepNavigationButtonsProps> = ({ activeStep, steps, handleNext, handleBack }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
      <Button disabled={activeStep === 0} onClick={handleBack} label="Back" size="large" variant="outlined" />
      <Button
        variant="contained"
        color="primary"
        onClick={handleNext}
        label="Next"
        size="large"
        disabled={activeStep === steps.length - 1}
      />
    </Box>
  );
};
