import { OptimizerResourcesEnum } from "shared/enums/resourceMappings";
import { useMemo } from "react";
import {
  Datagrid,
  DateField,
  DateTimeInput,
  List,
  ReferenceField,
  SelectInput,
  ShowButton,
  TextField,
  TextInput,
} from "react-admin";
import { optimizerCertificationsTestsTypeChoices } from "../opti_enums";

export default function CertificationsTestsList() {
  const CertificationsTestsFilters = useMemo(
    () => [
      <DateTimeInput key="start_date__lt" label="Start date before" source="start_date__lt" />,
      <DateTimeInput key="start_date__gt" label="Start date after" source="start_date__gt" />,
      <DateTimeInput key="end_date__lt" label="End date before" source="end_date__lt" />,
      <DateTimeInput key="end_date__gt" label="End date after" source="end_date__gt" />,
      <DateTimeInput key="start_date" label="start_date" source="start_date" />,
      <DateTimeInput key="end_date" label="end_date" source="end_date" />,
      <TextInput key="fcr_engagement" label="FCR engagement" source="fcr_engagement" />,
      <TextInput key="afrr_engagement" label="AFRR engagement" source="afrr_engagement" />,
      <TextInput key="group_code" label="Code EDP (rte_edp code)" source="group_code" />,
      <SelectInput
        key="test_type"
        label="Test type"
        source="test_type"
        choices={optimizerCertificationsTestsTypeChoices}
      />,
    ],
    [],
  );

  return (
    <List filters={CertificationsTestsFilters}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <DateField showTime source="start_date" />
        <DateField showTime source="end_date" />
        <TextField source="fcr_engagement" />
        <TextField source="afrr_engagement" />
        <TextField source="group_code" label={"Code EDP (rte_edp code)"} />
        <ReferenceField
          source="certif_id"
          reference={OptimizerResourcesEnum.OptiCertifications}
          label={"Certification"}
        />
        <TextField source="test_type" />
        <ShowButton />
      </Datagrid>
    </List>
  );
}
