import AcUnitIcon from "@mui/icons-material/AcUnit";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AlignVerticalBottomIcon from "@mui/icons-material/AlignVerticalBottom";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import BuildIcon from "@mui/icons-material/Build";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ConstructionIcon from "@mui/icons-material/Construction";
import Crop169Icon from "@mui/icons-material/Crop169";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import EngineeringIcon from "@mui/icons-material/Engineering";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import GroupsIcon from "@mui/icons-material/Groups";
import LoginIcon from "@mui/icons-material/Login";
import NetworkCheckIcon from "@mui/icons-material/NetworkCheck";
import PetsIcon from "@mui/icons-material/Pets";
import PsychologyIcon from "@mui/icons-material/Psychology";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import SignalCellularNoSimIcon from "@mui/icons-material/SignalCellularNoSim";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import UpdateIcon from "@mui/icons-material/Update";
import WineBarIcon from "@mui/icons-material/WineBar";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import {
  AfrrEngagementResourcesEnum,
  EcpResourcesEnum,
  MaintenanceResourcesEnum,
  OptimizerResourcesEnum,
  PlanningResourcesEnum,
  RegelleistungResourcesEnum,
  RteCommunicationResourcesEnum,
  SiteResourcesEnum,
  TopNiveauResourcesEnum,
  UserResourcesEnum,
} from "shared/enums/resourceMappings";

export const menuItems = [
  {
    top: true,
    label: SiteResourcesEnum.Sites,
    // label: "Jbox",
    icon: <Crop169Icon />,
    listItems: [
      { label: "Sites", path: SiteResourcesEnum.Sites, icon: <FmdGoodIcon /> },
      { label: "Groups", path: SiteResourcesEnum.Groups, icon: <GroupsIcon /> },
    ],
  },
  {
    top: true,

    label: "Fleet Status",
    icon: <DeviceThermostatIcon />,
    listItems: [
      {
        label: "Maintenances",
        path: MaintenanceResourcesEnum.Maintenance,
        icon: <EngineeringIcon />,
      },
      { label: "Statuses", path: SiteResourcesEnum.Statuses, icon: <NetworkCheckIcon /> },
    ],
  },
  {
    top: true,

    label: "Plannification",
    icon: <AccessAlarmIcon />,
    listItems: [
      {
        label: "Plannings",
        path: PlanningResourcesEnum.Plannings,
        icon: <CalendarMonthIcon />,
      },
      {
        label: "Planning Masks",
        path: PlanningResourcesEnum.PlanningMasks,
        icon: <UpdateIcon />,
      },
    ],
  },
  {
    top: true,

    label: "Performance Tests",
    icon: <ConstructionIcon />,
    listItems: [
      { label: "Validation", path: SiteResourcesEnum.PerformanceChronicles, icon: <AlignVerticalBottomIcon /> },
      { label: "Generation", path: "performance-chronicles-generation", icon: <EngineeringIcon /> },
    ],
  },

  {
    top: true,

    label: "France",
    icon: <WineBarIcon />,
    listItems: [
      {
        label: "Regelleistung",
        icon: <RequestQuoteIcon />,
        listItems: [{ label: "Bids", path: RegelleistungResourcesEnum.RegelleistungBids, icon: <ReceiptIcon /> }],
      },
      {
        label: "RACOON",
        icon: <PetsIcon />,
        listItems: [
          {
            label: "Visualization Charts",
            path: "afrr-engagement",
            icon: <AssignmentTurnedInIcon />,
          },
          {
            label: "Bids",
            path: AfrrEngagementResourcesEnum.Point,
            icon: <RadioButtonCheckedIcon />,
          },
        ],
      },
      {
        label: "TOPNIVEAU",
        icon: <StarBorderIcon />,
        listItems: [
          {
            label: "Bids",
            path: TopNiveauResourcesEnum.TopNiveauBids,
            icon: <ReceiptIcon />,
          },
        ],
      },

      {
        label: "Tests TSO",
        icon: <BuildIcon />,
        listItems: [
          {
            label: "IEC Fake PAs",
            path: RteCommunicationResourcesEnum.RteCommunicationFakePas,
            icon: <ReceiptLongIcon />,
          },
          {
            label: "IEC Loss N level",
            path: RteCommunicationResourcesEnum.RteCommunicationAfrrTestConfig,
            icon: <SignalCellularNoSimIcon />,
          },
          { label: "Tests AFRR RTE", path: "/tests-afrr-rte", icon: <SsidChartIcon /> },
          { label: "Tests FCR RTE", path: "/tests-fcr-rte", icon: <SsidChartIcon /> },
        ],
      },
    ],
  },

  {
    top: true,
    label: "Finland",
    icon: <AcUnitIcon />,
    listItems: [
      {
        label: "ECP",
        path: EcpResourcesEnum.EcpReservePlanPoints,
        icon: <ReceiptIcon />,
      },
    ],
  },

  {
    top: true,

    label: "Optimizer",
    icon: <AutoFixHighIcon />,
    listItems: [
      {
        label: "Certification Tests",
        path: OptimizerResourcesEnum.OptiCertificationsTests,
        icon: <CheckCircleIcon />,
      },
      { label: "Certifications", path: OptimizerResourcesEnum.OptiCertifications, icon: <WorkspacePremiumIcon /> },
      {
        label: "Groups Certifications",
        path: OptimizerResourcesEnum.OptiGroupsCertifications,
        icon: <GroupWorkIcon />,
      },
      { label: "Opti tasks", path: OptimizerResourcesEnum.OptiTasks, icon: <PsychologyIcon /> },
    ],
  },

  {
    top: true,

    label: "Authentication",
    icon: <LoginIcon />,
    listItems: [{ label: "Users", path: UserResourcesEnum.Users, icon: <AccountCircleIcon /> }],
  },
];
