import { PlanningResourcesEnum } from "shared/enums/resourceMappings";
import { Alert, AlertTitle, Grid } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { useCallback, useMemo, useState } from "react";
import { Loading, Title, useCreate } from "react-admin";
import { useNavigate } from "react-router-dom";
import TestsRteInitialForm from "./TestsRteInitialForm";
import TestsRtePlanningsForm from "./TestsRtePlanningsForm";
import { getInitialPlanningValues } from "./fcrHelpers";

export default function TestsRtePlanningsContainer() {
  const [create, { isLoading, error }]: any = useCreate();
  const navigate = useNavigate();
  const [initialFormValues, setInitialFormValues] = useState<any>(null);
  const handleGeneratePlanningsClick = useCallback((values: any) => {
    setInitialFormValues(values);
  }, []);

  const initialValues = useMemo(() => getInitialPlanningValues(initialFormValues), [initialFormValues]);

  const onSubmit = useCallback(
    (values: any) => {
      const activePlannings = values.plannings.reduce((acc: any, currentPlanning: any) => {
        if (currentPlanning.disabled) return acc;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { disabled, duration, target_soc_type, hasDatesOnSameDay, name, ...selectedData } = currentPlanning;
        const formattedData: any = {
          ...selectedData,
          start_date: new Date(selectedData.start_date),
          end_date: new Date(selectedData.end_date),
        };
        for (const [key, value] of Object.entries(selectedData)) {
          if (key !== "start_date" && key !== "end_date") {
            formattedData[key] = value === "" ? null : value;
          }
        }
        return [...acc, formattedData];
      }, []);

      const data = initialFormValues.site_ids.reduce((acc: any, currentSite: any) => {
        return {
          ...acc,
          [currentSite]: activePlannings,
        };
      }, {});
      const resource = PlanningResourcesEnum.Plannings;

      create(
        resource,
        { data },
        {
          onSuccess: () => navigate(`/${resource}`),
        },
      );
    },
    [initialFormValues, create, navigate],
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
  });
  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
      <Title title="Tests RTE" />
      <Grid item p={(theme) => theme.spacing(2, 0)}>
        <TestsRteInitialForm handleClick={handleGeneratePlanningsClick} />
      </Grid>
      {initialFormValues && (
        <FormikProvider value={formik}>
          <Grid item>
            {error && (
              <Alert severity="error">
                <AlertTitle>Error in the plannings</AlertTitle>
                <strong>{error.message}</strong>
              </Alert>
            )}
            {isLoading && <Loading />}
            <TestsRtePlanningsForm />
          </Grid>
        </FormikProvider>
      )}
    </Grid>
  );
}
