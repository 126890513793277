import { SiteResourcesEnum } from "shared/enums/resourceMappings";
import { Edit, Loading, useRedirect, useUpdate } from "react-admin";
import { useParams } from "react-router-dom";
import GroupBaseForm from "./GroupBaseForm";

export const GroupEdit = () => {
  const { id } = useParams();
  const [update, { isLoading }]: any = useUpdate();
  const redirect = useRedirect();

  const handleSubmit = (values: any) => {
    const { country, ...data } = values; // eslint-disable-line
    data.group_data = data.type === "rte_edr" ? data.group_data : null;
    update(
      SiteResourcesEnum.Groups,
      { id, data },
      {
        onSuccess: ({ id }: any) => {
          redirect(`/${SiteResourcesEnum.Groups}${id}/show`);
        },
        onError: (error: any) => {
          console.log("error:", error);
        },
      },
    );
  };

  if (isLoading) return <Loading />;

  return (
    <Edit>
      <GroupBaseForm handleSubmit={handleSubmit} />
    </Edit>
  );
};
