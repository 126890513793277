import { MaintenanceResourcesEnum, SiteResourcesEnum } from "shared/enums/resourceMappings";
import {
  Datagrid,
  DateField,
  List,
  ReferenceArrayField,
  ReferenceField,
  TextField,
  useRecordContext,
} from "react-admin";

export default function GroupMaintenances() {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <List
      resource={MaintenanceResourcesEnum.Maintenance}
      filter={{
        end__gt: new Date().toISOString(),
        site_id__in: record.site_ids,
      }}
    >
      <Datagrid>
        <TextField source="id" sortable={false} />
        <DateField source="start" sortable={true} showTime />
        <DateField source="end" sortable={true} showTime />
        <ReferenceField source="site_id" sortable={false} reference={SiteResourcesEnum.Sites} link="show" />
        <ReferenceField
          source="site_id"
          sortable={false}
          reference={SiteResourcesEnum.Sites}
          link={false}
          label={"Groups"}
        >
          <ReferenceArrayField source="group_ids" reference={SiteResourcesEnum.Groups} filter={{ type: "rte_edr" }} />
        </ReferenceField>
        <TextField source="type" sortable={false} />
        <TextField source="operator" sortable={false} />
        <TextField source="comment" sortable={false} />
        <DateField source="updated_at" sortable={false} showTime />
        <DateField source="created_at" sortable={false} showTime />
      </Datagrid>
    </List>
  );
}
