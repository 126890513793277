import { AfrrEngagementResourcesEnum } from "shared/enums/resourceMappings";
import { useMemo } from "react";
import {
  Datagrid,
  DateField,
  DateInput,
  List,
  NumberField,
  NumberInput,
  ReferenceInput,
  TextField,
  TextInput,
} from "react-admin";

export const AfrrEngagementBidTimeSeriesList = () => {
  const filters = useMemo(
    () => [
      <DateInput label="Start Date" source="start_date" key="start_date" />,
      <DateInput label="Start Date (From)" source="start_date__gte" key="start_date__gte" />,
      <DateInput label="Start Date (To)" source="start_date__lte" key="start_date__lte" />,
      <DateInput label="End Date" source="end_date" key="end_date" />,
      <DateInput label="End Date (From)" source="end_date__gte" key="end_date__gte" />,
      <DateInput label="End Date (To)" source="end_date__lte" key="end_date__lte" />,
      <TextInput label="Direction" source="direction" key="direction" />,
      <TextInput label="Divisible" source="divisible" key="divisible" />,
      <TextInput label="Exclusivity Group" source="exclusivity_group" key="exclusivity_group" />,
      <TextInput label="Symmetrical Feature" source="symetrical_feature" key="symetrical_feature" />,
      <NumberInput label="Offered Quantity (Min)" source="offered_quantity__gte" key="offered_quantity__gte" />,
      <NumberInput label="Offered Quantity (Max)" source="offered_quantity__lte" key="offered_quantity__lte" />,
      <NumberInput label="Offered Price (Min)" source="offered_price__gte" key="offered_price__gte" />,
      <NumberInput label="Offered Price (Max)" source="offered_price__lte" key="offered_price__lte" />,
      <ReferenceInput
        label="Gate Document ID"
        source="gate_document_id"
        reference={AfrrEngagementResourcesEnum.GateDocument}
        key="gate_document_id"
      />,
    ],
    [],
  );

  return (
    <List filters={filters}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <DateField showTime locales={"fr-FR"} source="created_at" />
        <DateField showTime locales={"fr-FR"} source="updated_at" />
        <DateField showTime locales={"fr-FR"} source="start_date" />
        <DateField showTime locales={"fr-FR"} source="end_date" />
        <TextField source="direction" />
        <NumberField source="offered_quantity" />
        <NumberField source="offered_price" />
        <TextField source="divisible" />
        <TextField source="exclusivity_group" />
        <TextField source="symetrical_feature" />
        <TextField source="mrid" />
        <TextField source="gate_document_id" />
      </Datagrid>
    </List>
  );
};
