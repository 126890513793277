import { Alert, AlertColor } from "@mui/material";
import { DateTimeInput } from "@react-admin/ra-form-layout";
import { msalAccountName } from "app/providers/auth/authConfig";
import axios from "axios";
import { useEffect, useState } from "react";
import FormContainer from "shared/components/forms/FormContainer";
import { maintenanceTypeChoices } from "shared/enums/reactAdminChoices";
import { MaintenanceResourceMapping, MaintenanceResourcesEnum, SiteResourcesEnum } from "shared/enums/resourceMappings";
import { validateMaintenanceEndDate, validatePlanningTime } from "shared/utils/validations";

import {
  AutocompleteArrayInput,
  Create,
  ReferenceArrayInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useStore,
} from "react-admin";
import { FieldValues, SubmitHandler, useFormContext } from "react-hook-form";

export const MaintenanceCreate = () => {
  const [resultMessages, setResultMessages] = useState<string[]>([]);

  const handleSubmit: SubmitHandler<FieldValues> = async ({ site_ids, start, end, ...data }) => {
    setResultMessages([]); // Reset the messages state initially

    const url = `${process.env.REACT_APP_MAINTENANCE_API_URL}/${
      MaintenanceResourceMapping[MaintenanceResourcesEnum.Maintenance]
    }`;

    const requests = site_ids.map((site_id: number) =>
      axios.post(url, {
        ...data,
        start,
        end,
        site_id,
        operator: msalAccountName,
      }),
    );

    const results = await Promise.allSettled(requests).catch();

    const errorMessages = results.map((result: any, index) => {
      const siteId = site_ids[index];
      if (result.status === "rejected") {
        return `error Site ${siteId} : ${result.reason.response.data.detail.error}`;
      } else {
        return `success Site ${siteId} : Maintenance created`;
      }
    });

    setResultMessages(errorMessages);
  };

  return (
    <Create redirect="list">
      <SimpleForm mode="onBlur" onSubmit={handleSubmit}>
        <FormContainer>
          <SelectInput source="type" choices={maintenanceTypeChoices} validate={required()} />

          <DefaultValueSiteReferenceInput />
          <DateTimeInput
            ampm={false}
            source="start"
            validate={[validatePlanningTime, required()]}
            minDate={new Date()}
          />
          <DateTimeInput
            ampm={false}
            source="end"
            minDate={new Date()}
            validate={[validatePlanningTime, validateMaintenanceEndDate, required()]}
          />
          <TextInput source="comment" multiline />
        </FormContainer>
      </SimpleForm>
      {resultMessages && resultMessages.length > 0 && (
        <div>
          {resultMessages.map((msg, index) => {
            const serverity = msg.split(" ")[0];
            return (
              <Alert key={index} severity={serverity as AlertColor}>
                {msg}
              </Alert>
            );
          })}
        </div>
      )}
    </Create>
  );
};

const DefaultValueSiteReferenceInput = () => {
  const [siteShowId] = useStore(`${MaintenanceResourcesEnum.Maintenance}.list.siteShowId`);
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue("site_ids", [siteShowId]);
  }, [siteShowId, setValue]);

  return (
    <ReferenceArrayInput source="site_ids" reference={SiteResourcesEnum.Sites} perPage={100}>
      <AutocompleteArrayInput
        validate={required()}
        label="Site list"
        optionText={({ id, name }: any) => {
          return `${id} - ${name} `;
        }}
        blurOnSelect={false}
        disableCloseOnSelect
      />
    </ReferenceArrayInput>
  );
};
