import { combineDataProviders, DataProvider } from "react-admin";

import { dataProviderMappings, ResourceMapping } from "shared/enums/resourceMappings";
import nwreServicesDataprovider from "./nwreServicesDataprovider";

// eslint-disable-next-line
const dataProviderServices = dataProviderMappings.map(({ root, ...endpoints }) => {
  return {
    dataProvider: nwreServicesDataprovider(root) as DataProvider<string>,
    resources: Object.values(endpoints) as string[],
  };
});

const combinedDataProvider = combineDataProviders((resource) => {
  const targetedResource = ResourceMapping[resource];
  const targetedDataProvider = dataProviderServices.find((dataProviderService) => {
    return dataProviderService.resources.includes(targetedResource);
  });
  if (!targetedDataProvider) {
    throw new Error(`Resource ${resource} not found in dataProviderServices`);
  }
  return targetedDataProvider.dataProvider;
});

export default combinedDataProvider;
